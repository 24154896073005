<template>
  <BaseModal
    name="modal-upsell"
    size="lg"
    title="Configuraçoes do Upsell"
    @shown="openModal"
  >
    <div v-if="!dataModal.new_upsell">
      <h3 class="mb-2">Modal de Compra</h3>
      <p class="mb-3">
        Insira o script de compra em qualquer lugar da sua Página de Upsell.
        Sera Inserido um Modal com os dados do Upsell.
      </p>
      <div class="grid-input">
        <div class="code" id="code">{{ codeUpLast(dataModal) }}</div>
        <button class="copy" @click="copyAddressLast(codeUpLast(dataModal))">
          Copiar
        </button>
      </div>
    </div>
    <div v-if="dataModal.new_upsell">
      <h3 class="mb-2">Botão de Compra</h3>
      <p class="mb-3">
        Insira o botão de compra em qualquer lugar da sua Página de Upsell.
        Fique a vontade para customizá-lo.
      </p>
      <div class="grid-input">
        <div class="code" id="code">
          &lt;button data-greenn-one-click="false" data-greenn-upsell="{{
            dataModal.id
          }}" data-greenn-split="1"&gt;Compre Ja!&lt;/button&gt;
        </div>
        <button class="copy" @click="copyAddress()">Copiar</button>
      </div>

      <h3 class="mt-4 mb-2">Atributos Disponíveis</h3>
      <div class="grid-list">
        <h5>Atributo</h5>
        <h5>Descrição</h5>
        <p>data-greenn-one-click</p>
        <p>Permite que a compra seja efetivada com um unico clique</p>
        <p>data-greenn-upsell</p>
        <p>Informa o codigo do Upsell</p>
        <!-- <p>data-greenn-split</p>
        <p>Informa a quantidade de Parcelas</p> -->
      </div>
      <h3 class="mt-4 mb-2">Modal de Compra</h3>
      <p class="mb-3">
        Insira esse script em qualquer lugar do seu site. Preferencialmente
        antes da tag &lt;body&gt;. Caso ja tenha o upsell da Voomp na página,
        não é necessario importar novamente.
      </p>
      <div class="code mb-3">
        &lt;script&gt; (function (w, d, s, t) { if (w._greennUp) return;
        w._greennUp = t; var f = d.getElementsByTagName(s)[0], j =
        d.createElement(s); j.async = true; j.src = "{{ codeUp() }}?v=" + t;
        f.parentNode.insertBefore(j, f); })(window, document, "script",
        Date.now()); &lt;/script&gt;
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
export default {
  props: ["dataModal"],
  data() {
    return {};
  },
  methods: {
    codeUpLast(code) {
      var id = code.id;
      var inject = code.inject_html;
      if (inject) {
        inject = 'data-upsell-inject="true"';
      } else {
        inject = "";
      }
      if (
        window.location.host === "victoradm.innovaweb.com.br" ||
        process.env.VUE_APP_API_HOST === "https://victorapi.innovaweb.com.br"
      ) {
        return `<script src="https://testeallan.gpages.com.br/lucas/upsell/upsell.js" id="greennUpsell" ${inject} data-upsell-greenn-id="${id}"><\/script>`;
      } else {
        return `<script src="https://greenn.nyc3.digitaloceanspaces.com/upsell/upsell.js" id="greennUpsell" ${inject} data-upsell-greenn-id="${id}"><\/script>`;
      }
    },
    codeUp() {
      if (
        window.location.host === "victoradm.innovaweb.com.br" ||
        process.env.VUE_APP_API_HOST === "https://victorapi.innovaweb.com.br"
      ) {
        return `https://testeallan.gpages.com.br/lucas/upsell2/upsell.js`;
      } else {
        return `https://pay.voompcreators.com.br/assets/upsell.js`;
      }
    },
    copyAddressLast(code) {
      const el = document.createElement("textarea");
      el.value = code;
      document.getElementById("code").appendChild(el);
      el.select();
      document.execCommand("copy");
      document.getElementById("code").removeChild(el);

      this.$bvToast.toast("O codigo foi copiado com sucesso!", {
        title: "Copiado!",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    copyAddress() {
      const el = document.createElement("textarea");

      const code = document.getElementById("code").innerHTML;
      let code1 = code.replace("&lt;", "<");
      let code2 = code1.replace("&lt;", "<");
      let code3 = code2.replace("&gt;", ">");
      let code4 = code3.replace("&gt;", ">");

      document.getElementById("code").appendChild(el);

      el.value = code4;

      el.select();
      document.execCommand("copy");
      document.getElementById("code").removeChild(el);

      this.$bvToast.toast("O codigo foi copiado com sucesso!", {
        title: "Copiado!",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    onSubmit() {},
    openModal() {
      console.log(this.dataModal);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

p {
  font-size: 12px;
  font-weight: 300;
}
.grid-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-input {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 3px;
  .copy {
    border: none;
    color: #333;
    background: #f7f7f7;
    outline: none !important;
    font-weight: 600;
    height: auto;
    padding: 0 42px !important;
    border-radius: 5px;
    font-size: 14px;

    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
}

.code {
  background: rgb(27, 26, 29);
  color: white;
  height: auto;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  border-radius: 3px;
}
</style>
