<template>
  <div fluid class="body">
    <!-- Button Create New Custom Checkout -->
    <div class="d-flex justify-content-end">
      <BaseButton
        variant="secondary"
        @click="openConfig('checkout')"
        id="criar_produto"
      >
        Criar novo tema
      </BaseButton>
    </div>
    <!-- Loading -->
    <div
      key="loading"
      v-if="loading"
      class="py-5 my-5 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>
    <!-- Number of Found Custom Checkout  -->
    <h5 v-if="!loading && !config && checkouts.length">
      {{ checkouts.length }} Temas encontrados
    </h5>
    <!-- Custom Checkout List -->
    <b-row
      v-if="!loading && !config && checkouts.length"
      key="lista"
      class="container"
      data-anima="top"
    >
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="mb-4 mt-4"
        v-for="item in checkouts"
        :key="item.id"
      >
        <div class="checkout">
          <div class="thumb">
            <Preview :item="item"></Preview>
          </div>
          <div class="info">
            <div>
              <p>{{ item.title }}</p>
              <span>Criado em {{ item.created_at | moment }}</span>
            </div>
            <b-dropdown
              size="sm"
              right
              dropup
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="float-right drop-personalizado"
            >
              <template v-slot:button-content>
                <img src="@/assets/img/icons/more.svg" class="btn-more-check" />
              </template>

              <b-dropdown-item @click="editCheckout(item.id)" class="drop-item">
                Editar Checkout
              </b-dropdown-item>

              <b-dropdown-item
                @click="deleteCheckout(item.id)"
                class="drop-item"
              >
                Deletar Checkout
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Message of Custom Checkout Not Found -->
    <b-row
      v-if="!loading && checkouts.length == 0 && !config"
      key="nao-encontrado"
      class="justify-content-center"
    >
      <p class="nao-encontrado">Nenhum tema foi encontrado</p>
    </b-row>
    <!-- Config of Selected Custom Checkout -->
    <div v-if="!loading && config" data-anima="top">
      <!-- Name of Custom Checkout -->
      <b-col cols="12" md="6" class="mt-5">
        <b-form-group label="Nome do Checkout" label-for="url" data-anima="top">
          <b-form-input
            v-model="checkout_name"
            type="text"
            placeholder="Nome do Checkout"
            data-form="modificado"
            data-anima="top"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- List of Custom Configs -->
      <b-row key="config" class="container">
        <b-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          class="checkout-config mb-4 mt-4 anime"
          v-for="item in temas"
          :key="item.id"
          @click="activeTheme(item)"
        >
          <span :class="{ active: tema_active === item.id }">
            <img
              :src="require(`@/assets/img/checkout/${item.img}.svg`)"
              class="btn-more"
            />
            <div>
              <p>{{ item.title }}</p>
              <p>{{ item.subtitle }}</p>
            </div>
          </span>
        </b-col>
      </b-row>
      <div v-if="tema_active === 0" data-anima="top">
        <p class="title-config ml-3 mt-4">01 Cores de Fundo</p>
        <b-row class="container">
          <b-col cols="12" md="4" @click="changeBackground('light')">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': cor_fundo === 'light' }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Tema Claro</p>
                <span>
                  Este é um texto exemplo para visualizar como ficará no
                  checkout
                </span>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="4" @click="changeBackground('dark')">
            <div
              class="container-cor-fundo anime tema-escuro-bg"
              :class="{ 'active-item': cor_fundo === 'dark' }"
            >
              <div>
                <div class="block block2">
                  <div></div>
                </div>
                <p class="color-white">Tema Escuro</p>
                <span class="color-white">
                  Este é um texto exemplo para visualizar como ficará no
                  checkout
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 0" data-anima="top">
        <p class="title-config ml-3 mt-4">02 Cores do Tema</p>
        <b-row class="container config-color">
          <b-col cols="12" md="3" class="d-flex">
            <div class="color" :style="'background:' + color.background + ';'">
              <span :style="'background:' + color.color + ';'"></span>
            </div>
            <p class="title-config ml-3">Cor Padrão</p>
          </b-col>
          <b-col cols="12" md="8" class="d-flex gap">
            <div
              @click="selectColor(color)"
              class="color anime"
              v-for="color in colors"
              :key="color.id"
              :style="'background:' + color.background + ';'"
            >
              <span :style="'background:' + color.color + ';'"></span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 1" data-anima="top">
        <p class="title-config ml-3 mt-4">01 Alterar Logotipo</p>
        <div class="recomendado ml-3 mt-2">
          <p class="">
            * seu logotipo apenas será mostrado se não houver 'banner superior'
          </p>
        </div>
        <b-col cols="12" md="12">
          <b-form-group label="" class="img-produto">
            <vue-upload-multiple-image
              :data-images="logotipo"
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              idUpload="logotipo"
              editUpload="logotipo"
              :multiple="false"
              dragText="🖼️ Logotipo"
              browseText="Procurar imagens"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </b-form-group>
          <div class="recomendado">
            <p>Recomendado:</p>
            <p>100px de largura</p>
            <p>50px de altura</p>
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 2" data-anima="top">
        <p class="title-config ml-3 mt-4">01 Alterar Banner Superior</p>
        <div class="recomendado ml-3 mt-2">
          <p class="">* 'banner superior' substitui seu logotipo.</p>
        </div>
        <b-col cols="12" md="12">
          <b-form-group label="" class="img-produto">
            <vue-upload-multiple-image
              :data-images="banner_sup"
              @upload-success="uploadImageSuccessBS"
              @before-remove="beforeRemoveBS"
              @edit-image="editImageBS"
              idUpload="banner_sup"
              editUpload="banner_sup"
              :multiple="false"
              dragText="🖼️ Banner Superior"
              browseText="Procurar imagens"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </b-form-group>
          <div class="recomendado">
            <p>Recomendado:</p>
            <p>1250px de largura</p>
            <p>350px de altura</p>
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 3" data-anima="top">
        <p class="title-config ml-3 mt-4">01 Alterar Banner Rodapé</p>
        <b-col cols="12" md="12">
          <b-form-group label="" class="img-produto">
            <vue-upload-multiple-image
              :data-images="banner_rod"
              @upload-success="uploadImageSuccessBR"
              @before-remove="beforeRemoveBR"
              @edit-image="editImageBR"
              idUpload="banner_rod"
              editUpload="banner_rod"
              :multiple="false"
              dragText="🖼️ Banner Rodapé"
              browseText="Procurar imagens"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </b-form-group>
          <div class="recomendado">
            <p>Recomendado:</p>
            <p>1250px de largura</p>
            <p>350px de altura</p>
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 4" data-anima="top">
        <p class="title-config ml-3 mt-4">01 Alterar Banner Lateral</p>
        <b-col cols="12" md="12">
          <b-form-group label="" class="img-produto">
            <vue-upload-multiple-image
              :data-images="banner_lat"
              @upload-success="uploadImageSuccessBL"
              @before-remove="beforeRemoveBL"
              @edit-image="editImageBL"
              idUpload="banner_lat"
              editUpload="banner_lat"
              :multiple="false"
              dragText="🖼️ Banner Lateral"
              browseText="Procurar imagens"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>
          </b-form-group>
          <div class="recomendado">
            <p>Recomendado:</p>
            <p>370px de largura</p>
            <p>1080px de altura</p>
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 5" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 Modifique o texto do botão de compra
        </p>
        <b-col cols="12" md="5">
          <b-form-group label="" class="texto-botao">
            <b-form-input
              name="texto-botao"
              id="texto-botao"
              placeholder="Ex: Comprar agora, Reservar produto"
              v-model="button_text"
              type="text"
              autocomplete="off"
              maxlength="35"
            ></b-form-input>
          </b-form-group>

          <div v-if="button_text" data-anima="bottom" class="exemplo-btn">
            <p>Botão de exemplo:</p>
            <BaseButton
              variant="info3"
              :style="'background:' + color.color + ';'"
              >{{ button_text }}</BaseButton
            >
          </div>
        </b-col>
      </div>
      <div v-if="tema_active === 6" data-anima="top">
        <span class="title-config destaque ml-3 mt-5"
          >* Apenas para produtos de assinatura</span
        >
        <p class="title-config ml-3 mt-4">01 Posição das informações</p>
        <b-row class="container">
          <b-col cols="12" md="4" @click="trial_position = 'top'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': trial_position === 'top' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Card do produto</p>
                <span>
                  Informações do trial ficará no topo do checkout, dentro do
                  card do produto
                </span>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="4" @click="trial_position = 'bottom'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': trial_position === 'bottom' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Abaixo das parcelas</p>
                <span>
                  Informações do trial ficará no abaixo das parcelas, próximo ao
                  botão de compra
                </span>
              </div>
            </div>
          </b-col>
        </b-row>

        <p class="title-config ml-3 mt-4">02 Informações dos valores</p>
        <b-row class="container">
          <b-col cols="12" md="4" @click="trial_info = 'total'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': trial_info === 'total' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Mostrar o total</p>
                <span>
                  Mostrar o valor total da assinatura no texto do trial
                </span>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="4" @click="trial_info = 'fixa'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': trial_info === 'fixa' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Parcela fixa</p>
                <span>
                  Se a parcela fixa estiver configurada, mostrar a mesma no
                  texto do trial
                </span>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="container">
          <b-col cols="12" class="exemplo-btn">
            <p>Exemplo das informações do trial:</p>
            <b-badge variant="success">{{
              trial_info == "total"
                ? "Após período de avaliação de 7 dias: R$ 233,00/ semestre"
                : "Após período de avaliação de 7 dias: 3x de R$ 82,25 / semestre"
            }}</b-badge>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 7" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 Adicione o seu Widget ID do Jivochat
        </p>
        <b-row class="container">
          <b-col cols="12" md="5">
            <b-form-group label="" class="widget-id">
              <b-form-input
                name="widget-id"
                id="widget-id"
                placeholder="Aqui você deve colocar o ID do seu Widget"
                v-model="jivochat_id"
                type="text"
                autocomplete="off"
                maxlength="15"
              ></b-form-input>
            </b-form-group>
            <div class="recomendado">
              <p>
                Com base no seu Widget ID, iremos adicionar o Jivochat neste
                checkout
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 8" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 Como serão as etapas do seu checkout ?
        </p>
        <b-row class="container">
          <b-col cols="12" md="4" @click="step_checkout = 'default'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': step_checkout === 'default' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>03 Etapas (padrão)</p>
                <span>
                  Checkout será dividido por algumas etapas (dados do comprador,
                  endereço e dados do pagamento)
                </span>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="4" @click="step_checkout = 'one_step'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': step_checkout === 'one_step' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>01 Etapa</p>
                <span>
                  Checkout terá apenas uma etapa para o preenchimento de dados,
                  ou seja, cliente preenche os dados e clica para comprar</span
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 9" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 Mostrar notificações de venda no Checkout ?
        </p>
        <b-row class="container">
          <b-col cols="12" md="4" @click="notification = 'off'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': notification === 'off' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Não Mostrar Notificações (padrão)</p>
                <span> Checkout não ira mostrar as notificações de venda </span>
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            md="4"
            @click="notificationON('b-toaster-top-right')"
          >
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': notification === 'on' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Mostrar Notificações</p>
                <span>
                  Checkout mostrará as notificaçoes de venda com base nas suas
                  configurações.</span
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="tema_active === 9 && notification === 'on'" data-anima="top">
        <p class="title-config ml-3 mt-4">
          02 Como serão buscada as notificações ?
        </p>
        <b-row class="container">
          <b-col cols="12" md="4" @click="notification_type = 'quantity'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': notification_type === 'quantity' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Por Quantidade (padrão)</p>
                <span>
                  Buscar as vendas por quantidade, Ex: As ultimas 50 Vendas.
                </span>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="4" @click="notification_type = 'time'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': notification_type === 'time' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Por Tempo</p>
                <span>
                  Buscar as vendas por tempo, Ex: As vendas nos ultimos 50
                  Minutos.
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <p class="title-config ml-3 mt-4">
          03 Informe
          {{ notification_type === "time" ? "o tempo" : "a quantidade" }}
        </p>
        <b-row class="container">
          <b-col cols="12" md="5">
            <b-form-group label="" class="widget-id">
              <b-form-input
                name="widget-id"
                id="widget-id"
                placeholder="Ex: 50"
                v-model="notification_quantity"
                type="number"
                autocomplete="off"
                maxlength="3"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('widget-id')">
                Preencha o campo
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="title-config ml-3 mt-4">
          04 Informe o intervalo entre as notificações (em segundos)
        </p>
        <b-row class="container">
          <b-col cols="12" md="5">
            <b-form-group label="" class="widget-id">
              <div class="d-flex mt-2" style="gap: 30px">
                <div>
                  <b-form-input
                    name="notification_interval-1"
                    placeholder="Ex: 15"
                    v-model="notification_interval[0]"
                    type="number"
                    v-mask="'##'"
                    min="15"
                    maxlength="2"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('notification_interval-1')"
                  >
                    Preencha o campo
                  </b-form-invalid-feedback>
                </div>

                <div>
                  <b-form-input
                    name="notification_interval-2"
                    placeholder="Ex: 60"
                    v-model="notification_interval[1]"
                    type="number"
                    v-mask="'##'"
                    max="60"
                    maxlength="2"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('notification_interval-2')"
                  >
                    Preencha o campo
                  </b-form-invalid-feedback>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="title-config ml-3 mt-4">
          05 Informe a posição das Notificações
        </p>
        <b-row class="container">
          <b-col
            cols="12"
            md="4"
            @click="notificationON('b-toaster-top-right')"
          >
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item': notification_position === 'b-toaster-top-right',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Superior Direito</p>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
            @click="notificationON('b-toaster-top-center')"
          >
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item': notification_position === 'b-toaster-top-center',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Superior no Centro</p>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="4" @click="notificationON('b-toaster-top-left')">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item': notification_position === 'b-toaster-top-left',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Superior Esquerdo</p>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
            @click="notificationON('b-toaster-bottom-right')"
          >
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item':
                  notification_position === 'b-toaster-bottom-right',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Inferior Direito</p>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
            @click="notificationON('b-toaster-bottom-center')"
          >
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item':
                  notification_position === 'b-toaster-bottom-center',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Inferior no Centro</p>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
            @click="notificationON('b-toaster-bottom-left')"
          >
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{
                'active-item':
                  notification_position === 'b-toaster-bottom-left',
              }"
            >
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Inferior Esquerdo</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="tema_active === 10" data-anima="top">
        <p class="title-config ml-3 mt-4">01 Escassez no Checkout</p>
        <b-row class="container">
          <b-col cols="12" md="5">
            <b-form-group label="" label-for="scarcity ">
              <b-form-checkbox
                v-model="scarcity"
                name="scarcity"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  Escassez {{ scarcity ? "ativada" : "desativada" }}
                  <span class="descricao-checkbox"
                    >No topo do checkout será apresentado o banner de Escassez
                    com os dados configurados</span
                  >
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="7" v-if="scarcity" data-anima="right">
            <div
              class="exemplo-escassez"
              :style="'background:' + scarcity_background_color + ';'"
            >
              <div class="flex-escassez">
                <img src="@/assets/img/icons/escassez.svg" />
                <div>
                  <h5>{{ scarcity_title || "Título Exemplo" }}</h5>
                  <p>
                    {{ scarcity_subtitle || "Essa é uma descrição exemplo " }}
                  </p>
                </div>
              </div>

              <h5 class="timer">01:32:27</h5>
            </div>
          </b-col>
        </b-row>

        <div v-if="scarcity" data-anima="top">
          <p class="title-config ml-3 mt-4">02 Título da Escassez</p>
          <b-row class="container">
            <b-col cols="12" md="5">
              <b-form-group label="" class="scarcity_title">
                <b-form-input
                  name="scarcity_title"
                  id="scarcity_title"
                  placeholder="Ex: Aproveite agora"
                  v-model="scarcity_title"
                  type="text"
                  autocomplete="scarcity_title"
                  maxlength="27"
                  v-validate="'required'"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('scarcity_title')">
                  Preencha o título
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <p class="title-config ml-3 mt-4">03 Uma breve descrição</p>
          <b-row class="container">
            <b-col cols="12" md="5">
              <b-form-group label="" class="scarcity_subtitle">
                <b-form-input
                  name="scarcity_subtitle"
                  id="scarcity_subtitle"
                  placeholder="Ex: Aproveite agora"
                  v-model="scarcity_subtitle"
                  type="text"
                  autocomplete="scarcity_subtitle"
                  maxlength="47"
                  v-validate="'required'"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="!errors.has('scarcity_subtitle')"
                >
                  Preencha a descrição
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <p class="title-config ml-3 mt-4">
            04 Contador deve iniciar com quantos minutos ? (Min 01 | Max 60)
          </p>
          <b-row class="container">
            <b-col cols="12" md="5" id="minutos-escassez">
              <b-form-group label="" class="scarcity_time">
                <b-form-input
                  name="scarcity_time"
                  id="scarcity_time"
                  v-model="scarcity_time"
                  placeholder="Ex: mm"
                  type="number"
                  v-mask="['##']"
                  maxlength="60"
                  autocomplete="scarcity_time"
                  v-validate="
                    'required|date_format:mm|min_value:1|max_value:60'
                  "
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('scarcity_time')">
                  Preencha os minutos. Minímo: 01 | Máximo: 60
                </b-form-invalid-feedback>
                <div class="recomendado">
                  <p>Formato mm.</p>
                </div>
              </b-form-group>
            </b-col>
            <b-tooltip
              target="minutos-escassez"
              title="Minímo: 01 | Máximo: 60 - Preencha no formato mm. Para 7 minutos, digite '07', para 60 minutos, digite '60'"
            />
          </b-row>

          <p class="title-config ml-3 mt-4">
            05 Cor de fundo do banner de Escassez
          </p>
          <b-row class="container config-color">
            <b-col cols="12" md="3" class="d-flex">
              <div
                class="color"
                :style="'background:' + scarcity_background_color + ';'"
              ></div>
              <p class="title-config ml-3">Cor do Banner</p>
            </b-col>
            <b-col cols="12" md="8" class="d-flex gap">
              <div
                @click="selectColorEscassez(color)"
                class="color anime"
                v-for="color in colors"
                :key="color.id"
                :style="'background:' + color.color + ';'"
              ></div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div v-if="tema_active === 11" data-anima="top">
        <p class="title-config ml-3 mt-4">01 Personalizar Order Bump</p>
        <b-row class="container">
          <b-col cols="12" md="5">
            <b-form-group label="" label-for="scarcity ">
              <b-form-checkbox
                v-model="ob_custom"
                name="scarcity"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  Personalizar Order Bump:
                  {{ ob_custom ? "ativado" : "desativado" }}
                  <span class="descricao-checkbox"
                    >Você podera personalizar o Order Bump no Checkout</span
                  >
                </p>
              </b-form-checkbox>
            </b-form-group>
            <div v-if="ob_custom" data-anima="top">
              <p class="title-config mt-4">02 Título do Order Bump</p>
              <b-row class="container">
                <b-col cols="12" md="12" class="not_padding">
                  <b-form-group label="" class="ob_title">
                    <b-form-input
                      name="ob_title"
                      id="ob_title"
                      placeholder="Ex: Aproveite e compre junto 🔥"
                      v-model="ob_title"
                      type="text"
                      autocomplete="ob_title"
                      maxlength="50"
                      v-validate="'required'"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('ob_title')">
                      Preencha o título
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="title-config mt-4">03 Sub-Título do Order Bump</p>
              <b-row class="container">
                <b-col cols="12" md="12" class="not_padding">
                  <b-form-group label="" class="ob_subtitle">
                    <b-form-input
                      name="ob_subtitle"
                      id="ob_subtitle"
                      placeholder="Ex: Sim, eu quero!"
                      v-model="ob_subtitle"
                      type="text"
                      autocomplete="ob_subtitle"
                      maxlength="27"
                      v-validate="'required'"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="!errors.has('ob_subtitle')"
                    >
                      Preencha o sub-título
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="title-config mt-4">04 Descrição do Order Bump</p>
              <b-row class="container">
                <b-col cols="12" md="12" class="not_padding">
                  <b-form-group label="" class="ob_title">
                    <b-form-checkbox
                      v-model="ob_description"
                      name="scarcity"
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        Descrição Order Bump:
                        {{ ob_description ? "ativado" : "desativado" }}
                        <span class="descricao-checkbox"
                          >Você podera ocultar a descrição do Order Bump</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col cols="12" md="7" v-if="ob_custom" data-anima="right">
            <p class="titleBump">
              {{ ob_title }}
            </p>
            <div class="order-bump" @click="checkbox = !checkbox">
              <div
                class="header"
                :style="`background:${ob_background_color.color};`"
              >
                <div>
                  <b-form-checkbox
                    style="position: relative; top: 15px"
                    v-model="checkbox"
                  >
                    <p class="ml-2 info-value">
                      {{ ob_subtitle }}
                    </p>
                  </b-form-checkbox>
                </div>
                <p class="info-value" style="position: relative; top: 15px">
                  R$ 600,00
                </p>
              </div>
              <div
                class="base"
                :style="
                  ob_border !== 'off'
                    ? ob_border === 'pontilhada'
                      ? `border-left: ${ob_border_px}px dashed ${ob_background_color.color}; border-right: ${ob_border_px}px dashed ${ob_background_color.color}; border-bottom: ${ob_border_px}px dashed ${ob_background_color.color}; background:${ob_background_color.background};`
                      : `border-bottom:  ${ob_border_px}px solid ${ob_background_color.color}; border-right:  ${ob_border_px}px solid ${ob_background_color.color}; border-left:  ${ob_border_px}px solid ${ob_background_color.color}; background:${ob_background_color.background};`
                    : `border:none; background:${ob_background_color.background};`
                "
              >
                <div class="img">
                  <img src="@/assets/logo-app.png" alt="logo" />
                </div>
                <div>
                  <p class="name">Nome do Produto</p>
                  <p
                    class="value"
                    :style="`color:${ob_background_color.color};`"
                  >
                    R$ 600,00
                  </p>
                  <p class="description" v-if="ob_description">
                    O homem que empenha todo o seu trabalho e imaginação em
                    oferecer por um dólar o mais possível, em vez de menos, está
                    condenado ao sucesso.
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <div v-if="ob_custom" data-anima="top">
          <p class="title-config ml-3 mt-4">05 Borda do Order Bump</p>
          <b-row class="container">
            <b-col cols="12" md="4" @click="ob_border = 'off'">
              <div
                class="container-cor-fundo mb-4 anime"
                :class="{ 'active-item': ob_border === 'off' }"
              >
                <!-- v-b-tooltip.hover.html="imageItem('top')" -->
                <div>
                  <div class="block">
                    <div></div>
                  </div>
                  <p>Sem Borda (padrão)</p>
                  <span> </span>
                </div>
              </div>
            </b-col>

            <b-col cols="12" md="4" @click="ob_border = 'lisa'">
              <div
                class="container-cor-fundo mb-4 anime"
                :class="{ 'active-item': ob_border === 'lisa' }"
              >
                <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
                <div>
                  <div class="block">
                    <div></div>
                  </div>
                  <p>Borda Lisa</p>
                  <span></span>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="4" @click="ob_border = 'pontilhada'">
              <div
                class="container-cor-fundo mb-4 anime"
                :class="{ 'active-item': ob_border === 'pontilhada' }"
              >
                <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
                <div>
                  <div class="block">
                    <div></div>
                  </div>
                  <p>Borda Pontilhada</p>
                  <span></span>
                </div>
              </div>
            </b-col>
            <p v-if="ob_border !== 'off'" class="title-config ml-3 mt-2">
              Espessura da Borda
            </p>
            <b-row class="container" v-if="ob_border !== 'off'">
              <b-col cols="12" md="4" class="">
                <b-form-group label="" class="ob_border_px">
                  <b-form-input
                    name="ob_border_px"
                    id="ob_border_px"
                    placeholder="Ex: 4"
                    v-model="ob_border_px"
                    type="number"
                    min="1"
                    max="15"
                    v-mask="'##'"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('ob_border_px')">
                    Preencha um valor
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-row>
          <p class="title-config ml-3 mt-4">06 Cor de fundo do Order Bump</p>
          <b-row class="container config-color">
            <b-col cols="12" md="3" class="d-flex">
              <div
                class="color"
                :style="'background:' + ob_background_color.color + ';'"
              ></div>
              <p class="title-config ml-3">Cor do Order Bump</p>
            </b-col>
            <b-col cols="12" md="8" class="d-flex gap">
              <div
                class="color anime"
                v-for="color in colors2"
                :key="color.id"
                :style="'background:' + color.color + ';'"
                @click="ob_background_color = color"
              ></div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div v-if="tema_active === 12" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 Garantia deve aparecer no checkout ?
        </p>
        <b-row class="container">
          <b-col cols="12" md="4" @click="warranty_checkout = 'off'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': warranty_checkout === 'off' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('top')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>NÃO mostrar a garantia</p>
                <span>
                  Dados da garantia do produto NÃO serão apresentadas no
                  checkout para o comprador
                </span>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="4" @click="warranty_checkout = 'on'">
            <div
              class="container-cor-fundo mb-4 anime"
              :class="{ 'active-item': warranty_checkout === 'on' }"
            >
              <!-- v-b-tooltip.hover.html="imageItem('bottom')" -->
              <div>
                <div class="block">
                  <div></div>
                </div>
                <p>Mostrar garantia</p>
                <span>
                  Dados da garantia do produto SERÃO apresentadas no checkout
                  para o comprador
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="tema_active === 13" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 Adicione o número de contato do WhastApp
        </p>
        <b-row class="container">
          <b-col cols="12" md="5">
            <b-form-group label="" class="">
              <VuePhoneNumberInput
                v-model="whatsapp"
                :translations="
                  navigator.browserLanguage != undefined
                    ? navigator.browserLanguage == 'pt-BR'
                      ? translations_pt
                      : translations_en
                    : navigator.language == 'pt-BR'
                    ? translations_pt
                    : translations_en
                "
                error-color="#ff0c37"
                size="lg"
                :default-country-code="
                  whatsapp_number != '' && whatsapp_number != null
                    ? wpp_country
                    : 'BR'
                "
                :preferred-countries="['BR', 'PT', 'US', 'FR', 'ES']"
                ref="telinput"
                :required="true"
                :countries-height="50"
                :border-radius="5"
                @update="onUpdate"
              />
              <b-form-invalid-feedback
                :state="submit ? !errors.has('whatsapp_number') : null"
              >
                Por favor, insira seu WhastApp.
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="recomendado">
              <p>
                Com base no seu contato, iremos adicionar um botão de
                redirecionamento para o chat do WhatsApp neste checkout
              </p>
            </div>
          </b-col>
        </b-row>
        <p class="title-config ml-3 mt-4">
          02 Adicione a mensagem personalizada do WhastApp
        </p>
        <b-row class="container">
          <b-col cols="12" md="12">
            <b-form-group label="">
              <b-form-textarea
                name="whatsapp_msg"
                id="whatsapp_msg"
                placeholder="Aqui você deve colocar o texto personalizado do WhatsApp"
                v-model="whatsapp_msg"
                autocomplete="off"
                size="lg"
                no-resize
              ></b-form-textarea>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('whatsapp_number') : null"
              >
                Por favor, insira sua mensagem.
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="recomendado">
              <p>
                Com base no sua mensagem, o comprador fará seu envio pelo chat
                do WhatsApp
              </p>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="tema_active === 14" data-anima="top">
        <p class="title-config ml-3 mt-4">
          01 Adicione o seu Widget ID do Zopim
        </p>
        <b-row class="container">
          <b-col cols="12" md="5">
            <b-form-group label="" class="widget-id">
              <b-form-input
                name="widget-id"
                id="widget-id"
                placeholder="Aqui você deve colocar o ID do seu Widget"
                v-model="zopim_id"
                type="text"
                autocomplete="off"
                maxlength="40"
              ></b-form-input>
            </b-form-group>
            <div class="recomendado">
              <p>
                Com base no seu Widget ID, iremos adicionar o Zopim neste
                checkout
              </p>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="footer d-flex justify-content-end">
        <BaseButton
          variant="secondary_outline"
          :disabled="loading"
          @click="cancel"
          class="mr-4 mt-4 mt-md-0"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          :disabled="loading"
          variant="secondary"
          @click="onSubmit"
          class="mt-4 mt-md-0"
          v-if="edit === null"
        >
          Salvar Tema
        </BaseButton>
        <BaseButton
          :disabled="loading"
          variant="secondary"
          @click="onSubmitUpdate"
          class="mt-4 mt-md-0"
          v-else
        >
          Salvar Tema
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import moment from "moment-timezone";
import CheckoutService from "@/services/resources/CheckoutService";
const service = CheckoutService.build();
import Preview from "@/components/Seller/Checkout/preview";
export default {
  components: {
    VuePhoneNumberInput,
    VueUploadMultipleImage,
    Preview,
  },
  data() {
    return {
      submit: false,
      loading: false,
      config: false,
      configUpsell: false,
      edit: null,
      title: "Checkouts",
      client: {
        width: 0,
      },
      temas: [
        { id: 0, title: "Alterar", subtitle: "Cores do Tema", img: "tema0" },
        { id: 1, title: "Alterar", subtitle: "Logotipo", img: "tema1" },
        {
          id: 2,
          title: "Adicionar",
          subtitle: "Banner Superior",
          img: "tema2",
        },
        { id: 3, title: "Adicionar", subtitle: "Banner Rodapé", img: "tema3" },
        { id: 4, title: "Adicionar", subtitle: "Banner Lateral", img: "tema4" },
        { id: 5, title: "Texto do botão", subtitle: "de compra", img: "tema1" },
        { id: 6, title: "Informações", subtitle: "do Trial", img: "tema0" },
        { id: 7, title: "Adicione o", subtitle: "Jivochat", img: "tema0" },
        { id: 8, title: "Etapas ", subtitle: "do Checkout", img: "tema1" },
        { id: 9, title: "Notificações ", subtitle: "de Venda", img: "tema0" },
        {
          id: 10,
          title: "Configurações ",
          subtitle: "de Escassez",
          img: "tema2",
        },
        { id: 11, title: "Order", subtitle: "Bump", img: "tema3" },
        { id: 12, title: "Garantia", subtitle: "do Produto", img: "tema4" },
        {
          id: 13,
          title: "Adicionar",
          subtitle: "Botão WhatsApp",
          img: "tema1",
        },
        { id: 14, title: "Adicione o", subtitle: "Zopim", img: "tema0" },
      ],
      ob_custom: false,
      ob_title: "Aproveite e compre junto 🔥",
      ob_subtitle: "Sim, eu quero!",
      ob_description: true,
      ob_border: "off",
      ob_border_px: 2,
      ob_background_color: {
        color: "#FF0C37",
        background: "rgba(255, 12, 55, 0.1)",
      },
      checkbox: false,
      scarcity: false,
      scarcity_title: "",
      scarcity_subtitle: "",
      scarcity_time: "",
      scarcity_background_color: "#00AF7B",
      button_text: "",
      jivochat_id: "",
      zopim_id: "",
      trial_position: "top",
      step_checkout: "default",
      notification: "off",
      warranty_checkout: "off",
      notification_type: "quantity",
      notification_quantity: null,
      notification_interval: ["15", "50"],
      notification_position: "b-toaster-top-right",
      trial_info: "total",
      tema_active: 0,
      checkout_name: "",
      cor_fundo: "light",
      color: { id: 8, color: "#002363", background: "#90C2F1" },
      whatsapp: "",
      whatsapp_number: "",
      whatsapp_msg: "",
      banner_sup: [],
      banner_rod: [],
      banner_lat: [],
      logotipo: [],
      checkouts: [],
      colors: [
        { color: "#002363", background: "rgba(33, 51, 210, 0.3)" },
        { color: "#FF0C37", background: "rgba(255, 12, 55, 0.3)" },
        { color: "#FFCB37", background: "rgba(255, 203, 55, 0.3)" },
        { color: "#2ED7EE", background: "rgba(46, 215, 238, 0.3)" },
        { color: "#5D21D2", background: "rgba(93, 33, 210, 0.3)" },
        { color: "#17191F", background: "rgba(0, 9, 22, 0.3)" },
        { color: "#3483FA", background: "rgba(52, 131, 250, 0.3)" },
        { color: "#00AF7B", background: "rgba(0, 175, 123, 0.3)" },
        { color: "#002363", background: "#90C2F1" },
      ],
      colors2: [
        { color: "#FF0C37", background: "rgba(255, 12, 55, 0.1)" },
        { color: "#002363", background: "rgba(33, 51, 210, 0.1)" },
        { color: "#FFCB37", background: "rgba(255, 203, 55, 0.1)" },
        { color: "#2ED7EE", background: "rgba(46, 215, 238, 0.1)" },
        { color: "#5D21D2", background: "rgba(93, 33, 210, 0.1)" },
        { color: "#17191F", background: "rgba(0, 9, 22, 0.1)" },
        { color: "#3483FA", background: "rgba(52, 131, 250, 0.1)" },
        { color: "#00AF7B", background: "rgba(0, 175, 123, 0.1)" },
        { color: "#002363", background: "rgba(0, 228, 160, 0.1)" },
      ],
      maxLength: 48,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      translations_pt: {
        countrySelectorLabel: "Código do país",
        countrySelectorError: "Escolha um país",
        phoneNumberLabel: "Número de Telefone",
        example: "Exemplo: ",
      },
      translations_en: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Example: ",
      },
      navigator: navigator,
      wpp_country: "",
    };
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    baseURL() {
      return process.env.VUE_SALES_PAGE;
    },
    baseAdmURL() {
      return process.env.VUE_ADM_PAGE;
    },
    username() {
      return this.$store.getters.getProfile.username;
    },
    userID() {
      // return this.$store.getters.getProfile.username;
      return this.$store.getters.getProfile.id;
    },
    salesPage() {
      let r = "";
      for (var i = 0; i < this.links.length; i++)
        if (this.links[i].name == "MAIN") {
          r = this.baseURL + "/redirect/" + this.links[i].id;
        }
      return r;
    },
  },
  watch: {
    isMobile(value) {
      if (value) {
        this.maxLength = 33;
      } else this.maxLength = 48;
    },
  },
  methods: {
    notificationON(position) {
      this.notification_position = position;
      this.notification = "on";
      this.$bvToast.toast("{Cliente} acabou de comprar !", {
        title: "Nova venda realizada",
        variant: "success",
        toaster: position,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    selectColorEscassez(data) {
      this.scarcity_background_color = data.color;
    },
    imageItem(pos) {
      const img = require(`@/assets/img/trial/${pos}.png`);
      return `<img src="${img}"/>`;
    },
    editCheckout(id) {
      this.loading = true;
      service
        .read({ id: id })
        .then((response) => {
          this.config = true;

          this.edit = response.checkout.id;
          this.checkout_name = response.checkout.title;
          if (response.metas.theme !== null) {
            this.cor_fundo = response.metas.theme;
          }
          if (response.metas.top_thumb !== "") {
            this.banner_sup = [{ path: response.metas.top_thumb }];
          }
          if (response.metas.bottom_thumb !== "") {
            this.banner_rod = [{ path: response.metas.bottom_thumb }];
          }
          if (response.metas.side_thumb !== "") {
            this.banner_lat = [{ path: response.metas.side_thumb }];
          }
          if (response.metas.logotipo !== "") {
            this.logotipo = [{ path: response.metas.logotipo }];
          }
          this.color.color = response.metas.theme_color;
          this.color.background = response.metas.theme_color_bg;

          this.step_checkout = response.metas.step_checkout || "default";

          this.warranty_checkout = response.metas.warranty_checkout || "off";
          // escassez
          this.scarcity =
            response.metas.scarcity == "on" ? true : false || false;
          this.scarcity_title = response.metas.scarcity_title || "";
          this.scarcity_subtitle = response.metas.scarcity_subtitle || "";
          this.scarcity_time = response.metas.scarcity_time || "0";
          this.scarcity_background_color =
            response.metas.scarcity_background_color || "#00AF7B";

          if (response.metas.purchase_notification !== "") {
            this.notification = response.metas.purchase_notification;
          }
          if (response.metas.how_get_purchase_notification !== "") {
            this.notification_type =
              response.metas.how_get_purchase_notification || "quantity";
          }
          if (response.metas.quantity_purchase_notification !== "") {
            this.notification_quantity =
              response.metas.quantity_purchase_notification;
          }
          if (response.metas.type_purchase_notification !== "") {
            this.notification_position =
              response.metas.type_purchase_notification;
          }
          if (response.metas.minimum_purchase_notification_interval !== "") {
            this.notification_interval[0] =
              response.metas.minimum_purchase_notification_interval;
          }
          if (response.metas.maximum_purchase_notification_interval !== "") {
            this.notification_interval[1] =
              response.metas.maximum_purchase_notification_interval;
          }
          if (response.metas.ob_custom) {
            this.ob_custom = response.metas.ob_custom;
          }

          if (response.metas.ob_title) {
            this.ob_title = response.metas.ob_title;
          }

          if (response.metas.ob_subtitle) {
            this.ob_subtitle = response.metas.ob_subtitle;
          }

          if (response.metas.ob_description) {
            this.ob_description = response.metas.ob_description;
          }

          if (response.metas.ob_border) {
            this.ob_border = response.metas.ob_border;
          }

          if (response.metas.ob_border_px) {
            this.ob_border_px = response.metas.ob_border_px;
          }

          if (response.metas.zopim_id) {
            this.zopim_id = response.metas.zopim_id;
          }

          if (response.metas.ob_background_color) {
            this.ob_background_color = JSON.parse(
              response.metas.ob_background_color
            );
          }

          if (response.metas.whatsapp_number) {
            this.wpp_country = response.metas.whatsapp_number.split(".")[0];
            this.whatsapp_number = response.metas.whatsapp_number;
            this.whatsapp = response.metas.whatsapp_number.split(".")[2];
          }
          if (response.metas.whatsapp_msg) {
            this.whatsapp_msg = response.metas.whatsapp_msg;
          }

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    deleteCheckout(id) {
      this.loading = true;
      service
        .destroy(id)
        .then(() => {
          this.$bvToast.toast("Checkout excluído com sucesso ", {
            title: "Checkout",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.loading = false;
          this.fetchCheckouts();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    uploadImageSuccessBL(formData, index, fileList) {
      this.banner_lat = fileList;
    },
    beforeRemoveBL(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        this.banner_lat = [];
        done();
      }
    },
    editImageBL(formData, index, fileList) {
      this.banner_lat = fileList;
    },

    uploadImageSuccessBR(formData, index, fileList) {
      this.banner_rod = fileList;
    },
    beforeRemoveBR(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        this.banner_rod = [];
        done();
      }
    },
    editImageBR(formData, index, fileList) {
      this.banner_rod = fileList;
    },
    uploadImageSuccessBS(formData, index, fileList) {
      this.banner_sup = fileList;
    },
    beforeRemoveBS(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        this.banner_sup = [];
        done();
      }
    },
    editImageBS(formData, index, fileList) {
      this.banner_sup = fileList;
    },
    uploadImageSuccess(formData, index, fileList) {
      this.logotipo = fileList;
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        this.logotipo = [];
        done();
      }
    },
    editImage(formData, index, fileList) {
      this.logotipo = fileList;
    },
    cancel() {
      this.config = false;
      this.tema_active = 0;
      this.checkout_name = "";
      this.cor_fundo = "light";
      this.color = { color: "#002363", background: "#90C2F1" };
      this.whatsapp_number = "";
      this.whatsapp_msg = "";
      this.banner_sup = [];
      this.banner_rod = [];
      this.banner_lat = [];
      this.logotipo = [];
      this.edit = null;
    },
    // update
    onSubmitUpdate() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let data = {
            theme: this.cor_fundo,
            theme_color: this.color.color,
            theme_color_bg: this.color.background,
            top_thumb: this.banner_sup,
            bottom_thumb: this.banner_rod,
            side_thumb: this.banner_lat,
            logotipo: this.logotipo,
            button_text: this.button_text,
            trial_position: this.trial_position,
            step_checkout: this.step_checkout,
            trial_info: this.trial_info,
            jivochat_id: this.jivochat_id,
            zopim_id: this.zopim_id,
            purchase_notification: this.notification,
            how_get_purchase_notification: this.notification_type,
            quantity_purchase_notification: this.notification_quantity,
            minimum_purchase_notification_interval: this
              .notification_interval[0],
            maximum_purchase_notification_interval: this
              .notification_interval[1],
            type_purchase_notification: this.notification_position,
            whatsapp_number: this.whatsapp_number,
            whatsapp_msg: this.whatsapp_msg,

            warranty_checkout: this.warranty_checkout,
            // escassez
            scarcity: this.scarcity ? "on" : "off",
            scarcity_title: this.scarcity_title,
            scarcity_subtitle: this.scarcity_subtitle,
            scarcity_time: this.scarcity_time,
            scarcity_background_color: this.scarcity_background_color,
            ob_custom: this.ob_custom,
            ob_title: this.ob_title,
            ob_subtitle: this.ob_subtitle,
            ob_description: this.ob_description,
            ob_border: this.ob_border,
            ob_border_px: this.ob_border_px,
            ob_background_color: JSON.stringify(this.ob_background_color),
          };
          this.loading = true;

          service
            .createId({ id: this.edit, title: this.checkout_name })
            .then((response) => {
              this.submitMetas(response.id, data);
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        }
      });
    },
    onSubmit() {
      this.submit = true;

      let data = {
        theme: this.cor_fundo,
        theme_color: this.color.color,
        theme_color_bg: this.color.background,
        top_thumb: this.banner_sup,
        bottom_thumb: this.banner_rod,
        side_thumb: this.banner_lat,
        logotipo: this.logotipo,
        button_text: this.button_text,
        trial_position: this.trial_position,
        step_checkout: this.step_checkout,
        trial_info: this.trial_info,
        jivochat_id: this.jivochat_id,
        zopim_id: this.zopim_id,
        purchase_notification: this.notification,
        how_get_purchase_notification: this.notification_type,
        quantity_purchase_notification: this.notification_quantity,
        minimum_purchase_notification_interval: this.notification_interval[0],
        maximum_purchase_notification_interval: this.notification_interval[1],
        type_purchase_notification: this.notification_position,
        warranty_checkout: this.warranty_checkout,
        scarcity: this.scarcity ? "on" : "off",
        whatsapp_number: this.whatsapp_number,
        whatsapp_msg: this.whatsapp_msg,

        scarcity_title: this.scarcity_title,
        scarcity_subtitle: this.scarcity_subtitle,
        scarcity_time: this.scarcity_time,
        scarcity_background_color: this.scarcity_background_color,
        ob_custom: this.ob_custom,
        ob_title: this.ob_title,
        ob_subtitle: this.ob_subtitle,
        ob_description: this.ob_description,
        ob_border: this.ob_border,
        ob_border_px: this.ob_border_px,
        ob_background_color: JSON.stringify(this.ob_background_color),
      };

      this.loading = true;
      service
        .create({ title: this.checkout_name })
        .then((response) => {
          this.submitMetas(response.id, data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    submitMetas(id, data) {
      if (id === undefined) {
        id = this.edit;
      }

      if (data.top_thumb.length === 0 || data.top_thumb === null) {
        data.top_thumb = "";
      } else {
        data.top_thumb = data.top_thumb[0].path;
      }
      if (data.bottom_thumb.length === 0 || data.bottom_thumb === null) {
        data.bottom_thumb = "";
      } else {
        data.bottom_thumb = data.bottom_thumb[0].path;
      }
      if (data.side_thumb.length === 0 || data.side_thumb === null) {
        data.side_thumb = "";
      } else {
        data.side_thumb = data.side_thumb[0].path;
      }
      if (data.logotipo.length === 0 || data.logotipo === null) {
        data.logotipo = "";
      } else {
        data.logotipo = data.logotipo[0].path;
      }

      data.id = id + "/meta";
      service
        .createId(data)
        .then(() => {
          this.$bvToast.toast("Novo Tema Salvo com Sucesso", {
            title: "Checkout",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.cancel();
          this.fetchCheckouts();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    selectColor(data) {
      this.color = data;
    },

    changeBackground(theme) {
      this.cor_fundo = theme;
    },
    activeTheme(data) {
      this.tema_active = data.id;
    },
    openConfig(type) {
      this.zopim_id = "";
      if (type === "checkout") {
        this.config = true;
      } else if (type === "upsell") {
        this.configUpsell = true;
      }
    },
    handleResize() {
      if (this.client.width === 0) this.client.width = window.innerWidth;
      if (this.client.width > 0) this.client.width = window.screen.width;
    },
    fetchCheckouts() {
      // var data = {
      //   page: this.pagination.currentPage,
      //   per_page: this.pagination.perPage,
      // };
      // this.pagination.totalPages = 1;

      this.loading = true;

      service
        .search()
        .then((response) => {
          this.checkouts = response;
          // this.pagination.totalPages = response.last_page;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * On whatsapp number update, set whatsapp_number in formatt:
     * Country abbreviation.Country code.Mobile number.
     *
     * Ex: BR.55.31991234567
     *
     * @returns no return avaliable
     * @param Object payload of lib vue-phone-number-input
     * @author Sandro Souza <sandro@greenn.com.br>
     */
    onUpdate(payload) {
      let country_code = payload.formatInternational.split(" ")[0];
      let cel_number = payload.formattedNumber.substring(country_code.length);
      this.whatsapp_number =
        payload.countryCode +
        "." +
        country_code.substring(1) +
        "." +
        cel_number;
    },
  },
  mounted() {
    this.fetchCheckouts();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.vue-slider-process {
  background-color: #002363 !important;
}
.vue-slider-dot-handle {
  background-color: #002363 !important;
}
.vue-slider-rail {
  background-color: #c1f1e3 !important;
}
.vue-slider-dot-tooltip-inner {
  background-color: #002363 !important;
}
.vue-slider-dot-handle::after {
  background-color: #c1f1e3 !important;
}
</style>

<style>
.not_padding {
  padding: 0 !important;
}
.order-bump .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff !important;
  border-color: #ffffff !important;
  background-color: #ffffff !important;
}

.order-bump
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.564 0.75L2.974 4.362L1.436 2.812L0 4.26L2.974 7.25L8 2.193L6.564 0.75Z' fill='black'/%3E%3C/svg%3E%0A");
}

.country-selector__toggle {
  padding-top: 5px !important;
}

.country-selector__input,
.input-tel__input {
  padding-top: 25px !important;
  padding-left: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: "Montserrat", sans-serif !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
}

.country-selector__label,
.input-tel__label {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #333333 !important;
  line-height: 1.333rem;
  font-family: "Montserrat", sans-serif !important;
}
</style>

<style scoped>
.order-bump {
  width: 100%;
  cursor: pointer;
}

.order-bump .header {
  height: 51px;
  width: 100%;
  background: #002363;
  border-radius: 5px 5px 0px 0px;
  padding: 0px 32px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  user-select: none;
}

.order-bump .header .info-checkbox {
  color: white;
  letter-spacing: 0.2px;
  font-size: 14px;
  font-weight: 600;
}

.order-bump .header .info-value {
  color: white;
  letter-spacing: 0.2px;
  font-size: 14px;
  font-weight: 600;
}

.order-bump .base {
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  padding: 25px 32px;
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: 30px;
  pointer-events: none;
  user-select: none;
}

.order-bump .base .img {
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.order-bump .base .img img {
  object-fit: cover;
  border-radius: 5px;
}

.order-bump .base .description {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.order-bump .base .name {
  font-weight: 600;
  font-size: 14px;
  margin: 0 0 10px 0;
}

.order-bump .base .value {
  font-weight: 600;
  font-size: 14px;
  color: #2a63ab;
  margin: 0 0 15px 0;
}

.checkout {
  background: #ffffff;
  box-shadow: 2px 4px 10px rgba(237, 237, 240, 0.2);
  border-radius: 10px;
  height: 398.96px;
}

.thumb {
  height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  background: #f7f7f7;
  border-radius: 10px;
  width: 97%;
  height: 135px;
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  margin: auto;
}

.info p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  margin-bottom: 5px;
}

.info span {
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}

.info .btn {
  width: 40px;
  height: 40px;
  background: #ededf0;
  box-shadow: 0px 2px 3px rgba(237, 237, 240, 0.3);
  border-radius: 10px;
}

.checkout-config {
  height: 101px;
  box-sizing: border-box;
}

.checkout-config span {
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px 28px;
  border-radius: 5px;
  cursor: pointer;
  background: #ffffff;
}

.checkout-config .active {
  background: #333333;
}

.checkout-config .active p {
  color: #ffffff;
}

.checkout-config .active img {
  filter: invert(100%);
}

.checkout-config p {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-bottom: 5px;
  margin-left: 23px;
}

.container-cor-fundo {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 25px;
  cursor: pointer;
}

.container-cor-fundo p {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.container-cor-fundo span {
  font-weight: normal;
  font-size: 13px;
  color: #81858e;
}

.container-cor-fundo .block {
  background: rgba(51, 51, 51, 0.05);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.container-cor-fundo .block div {
  width: 10px;
  height: 10px;
  background: #333333;
  border-radius: 100%;
}

.container-cor-fundo .block2 {
  /* background: #17191F; */
  background: rgba(255, 255, 255, 0.1);
}

.container-cor-fundo .block2 div {
  background: #fff;
}

.title-config {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}
.title-config.destaque {
  color: #2a63ab;
}
.container-cor-fundo.active-back {
  background: #333;
}

.container-cor-fundo.active-back p {
  color: #fff;
}

.container-cor-fundo.active-back .block {
  background: #f4f4f4;
}

.container-cor-fundo .active-back .block div {
  background: #fff;
}

.container-cor-fundo.active-back .block2 {
  background: #17191f;
}

.config-color .color {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.config-color p {
  margin-top: 20px;
  margin-bottom: 0px;
}

.config-color .color span {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.config-color .gap {
  gap: 0 20px;
}

.footer {
  margin-top: 90px;
  margin-bottom: 90px;
}
.anime {
  transition: 0.3s;
}
.anime:hover {
  transform: scale(1.07);
}

.btn-more-check {
  background: rgba(0, 35, 99, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px 15px;
  border-radius: 5px;
}
.tema-escuro-bg {
  background: #17191f;
}
.color-white {
  color: #fff !important;
}
.active-item {
  position: relative;
}
.active-item::before {
  content: "✔️";
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
}
.recomendado {
  margin: 20px 0;
}
.recomendado p {
  margin: 0;
  color: #81858e;
  font-size: 14px;
}
.recomendado p + p {
  margin-top: 10px;
}
.exemplo-btn p {
  color: #81858e;
  font-size: 14px;
}
.badge-success {
  padding: 10px 15px;
  font-weight: 600;
  background-color: rgba(65, 137, 230, 0.15);
  color: #3483fa;
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
}
.exemplo-escassez {
  /* background: #3483fa; */
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
}
.exemplo-escassez h5,
.exemplo-escassez p {
  color: #fff;
  margin: 0;
}
.exemplo-escassez h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.exemplo-escassez .timer {
  font-size: 22px;
  letter-spacing: 3px;
}
.exemplo-escassez p {
  font-size: 15px;
  max-width: 300px;
}
.flex-escassez {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

#whatsapp_msg {
  height: 140px !important;
}
</style>
